import React, { useEffect, useState } from 'react';

import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import { VerticalYellowLineShape, IconLectureTime } from '../../../Atoms/Icons/Icons';
import QuoteMark from '../../../Atoms/QuoteMark/QuoteMark';
import AudioComponent from '../../../components/AudioComponent/AudioComponent';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import TextBlockComponent from '../../../components/ContentType/TextBlockComponent/TextBlockComponent';
import VisuelComponent from '../../../components/ContentType/VisuelComponent/VisuelComponent';
import Seo from '../../../components/Seo/Seo';
import Layout from '../../../Layout';
import ScrollIcon from '../../../Molecules/ScrollIcon/ScrollIcon';
import SocialShare from '../../../Molecules/SocialShare/SocialShare';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';
import { getImage, getBreadCrumb, removeHtmlTag } from '../../../Utils/Utils';
import './article.scss';

const classNames = require('classnames');

const MediaPage = ({ data, pageContext }) => {
  const article = data.article;
  const imageArray = data.allImages.edges;
  const { locale } = pageContext;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, article.path?.alias);

  /* const [allText, setAllText] = useState('');

  let globalText = '';

  useEffect(() => {
    setAllText(removeHtmlTag(article.body?.processed + '.' + '.' + globalText));
  }, [globalText]);*/

  return (
    <Layout imagesArray={imageArray}>
      <Seo title="Colas Article Page" />
      <div className={classNames('page_template', 'page_article')}>
        <div className="banner_fullwidth_visuel">
          <div className="visuel">
            {article?.relationships?.field_image?.drupal_internal__mid &&
              <Img
                style={{ height: '100%', width: '100%' }}
                imgStyle={{ objectFit: 'cover' }}
                fluid={getImage(imageArray, article.relationships?.field_image?.drupal_internal__mid)?.childImageSharp?.fluid}
              />
            }
          </div>
          <div className="visuel_overlay">
            <div className="wrapper_page">
              <Breadcrumb
                page_banner={true}
                data={{
                  // grandParentPage: { title: 'Acceuile', url: '/' },
                  gparent: { title: breadCrumb?.grandParent?.name, url: breadCrumb?.grandParent?.link },
                  parentPage: { title: breadCrumb?.parent?.name, url: breadCrumb?.parent?.link },
                  currentPage: { title: breadCrumb?.current?.name, url: breadCrumb?.current?.link },
                  locale: article.langcode
                }}
              />
            </div>
            <div className="scroll_icon_container">
              <ScrollIcon />
            </div>
          </div>
        </div>
        <div className={classNames('article_details', 'page_content')}>
          <div className="wrapper_page">
            <div className="share_date">
              <div className="publication_date">
                <p className="date">
                  <span className="day">{article.day}</span>
                  <span className="month">{article.month.toUpperCase()}</span>
                </p>
                <VerticalYellowLineShape className="bottom_line" />
                <p className="lecture_time">
                  <IconLectureTime className="time_icon" />
                  <span>{article.fields.readingTime.format}</span>
                </p>
              </div>
              <SocialShare
                title={article.title}
                linkTo={'/' + article.langcode + article.path.alias}
                vertical={true}
                hidemobile={true}
              />
            </div>
            <div className="wrapper_page_xs">
              <TitleSection title={article.title} />
              <p className="highlight" dangerouslySetInnerHTML={{ __html: article.field_sous_titre }} />
              <p dangerouslySetInnerHTML={{ __html: article.body?.processed }} />

              {article?.relationships?.field_blocs?.map((block, i) => {
                switch (block.__typename) {
                  case 'block_content__block_image':
                    return (
                      <VisuelComponent
                        key={i}
                        extremeLeft={true}
                        visuel={getImage(imageArray, block.relationships?.field_image?.drupal_internal__mid)?.childImageSharp}
                        alt="Test"
                        size="two_third"
                      />
                    );

                  case 'block_content__block_texte_simple':
                    return (
                      <TextBlockComponent
                        key={i}
                        title={{
                          type: block.field_title?.processed ? 'section_title' : undefined,
                          text: block.field_title?.processed || block.field_subtitle?.processed,
                          subtitle: block.field_subtitle?.processed,
                        }}
                        description={block.body?.processed}
                      />
                    );

                  case 'block_content__block_citation':
                    return (
                      <div className="quotemark_container" key={i}>
                        <QuoteMark
                          size="m"
                          textWeight="m"
                          text={block.field_citation}
                          lang={locale}
                        />
                      </div>
                    );

                  default:
                    break;
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

MediaPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

export const query = graphql`
  query ArticleTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
  ) {
    article: nodeArticle(
      path: {alias: {eq: $slug}
      langcode: {eq: $locale}}
    ) {
      langcode
      month: created(locale: $locale, formatString: "MMM")
      day: created(locale: $locale, formatString: "DD")
      path {
        alias
      }
      fields {
        readingTime {
          format
        }
      }
      title
      field_sous_titre
      body {
        processed
      }
      relationships {
        field_category {
          drupal_internal__tid
        }
        field_tags {
          name
          drupal_internal__tid
        }
        field_image {
          name
          drupal_internal__mid
        }
        field_blocs {
          __typename
          ... on Node {
            ... on block_content__block_texte_simple {
              field_subtitle {
                processed
              }
              field_title {
                processed
              }
              body {
                processed
                summary
              }
            }
            ... on block_content__block_image {
              field_alignment
              relationships {
                field_image {
                  name
                  drupal_internal__mid
                }
                field_mobile_image {
                  name
                  drupal_internal__mid
                }
              }
            }
            ... on block_content__block_citation {
              field_citation
            }
          }
        }
      }
    }
    allImages: allMediaImage(
      filter: {
        langcode: {eq: "fr"},
        drupal_internal__mid: {in: $imagesID}
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              localFile {
                publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                  fixed(width: 332, height: 332) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          langcode
        }
      }
    }

    allMenu :   allMenuLinkContentMenuLinkContent(
      filter: {langcode: {eq: $locale}}
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
  }
`;

export default MediaPage;
